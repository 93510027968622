<template>
  <div>
    <TheNavbar/>
    <div class="container mt-4">
      <!-- PAYMENTS TITLE -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <router-link :to="{name: 'Home'}" class="btn btn-link btn-micint-xr ripple ripple-primary mx-0">
            <i class="material-icons-outlined">&#xe408;</i>
            Go back
          </router-link>
        </div>
        <div class="col-md-12 animate fadeIn">
          <h1 class="fw-500 typography-headline-lg">Payments</h1>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <div class="card card-outline">
            <div class="card-header mt-2">
              <p class="overline text-muted">Payments details</p>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-borderless table-hover">
                  <tbody>
                  <tr>
                    <th scope="row" class="w-50">Total payout</th>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <th>Complete orders</th>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>Payout frequency</th>
                    <td>Weekly</td>
                  </tr>
                  <tr>
                    <th class="align-middle">Payment date</th>
                    <td>Mon Oct 18, 2021 <span class="badge badge-warning">Pending</span></td>
                  </tr>
                  <tr>
                    <th>Earnings</th>
                    <td>$0.00</td>
                  </tr>
                  <tr>
                    <th>Total payout</th>
                    <td>$0.00</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <div class="card card-outline">
            <div class="card-header mt-2">
              <p class="overline text-muted">Past payments</p>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-borderless table-hover">
                  <thead>
                  <tr>
                    <th>Day</th>
                    <th>Net payout</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="align-middle">
                      Mon Oct 18, 2021
                    </td>
                    <td class="align-middle">
                      $10.00
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";

export default {
  name: "Payment",
  components: {TheNavbar}
}
</script>
